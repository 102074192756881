<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>Recomendation History</h4>
        <!-- <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showModal"
        >
          New
        </b-button> -->
      </div>
      <div class="mb-2">
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Search by keyword"
              label-for="v-search-keyword"
            >
              <b-form-input
                id="h-search-keyword"
                v-model="filter"
                type="text"
                placeholder="search here..."
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive="sm"
        :items="recommendations"
        :fields="fields"
        sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        :filter-included-fields="filterOn"
      >
      <template #cell(cover)="data">
          <b-avatar :src="getImage(data.value)" />
        </template>
        <template
          #cell(recommendation_date)="data"
        >
          {{ date(data.value) }}
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="lg"
        class="my-0"
      />
    </b-card>
    <b-modal
      id="app-modal"
      :title="is_update ? 'Uptate' : 'Add New'"
      hide-footer
    >
      <b-card-text>
        <h5>Check First Paragraph</h5>
        Oat cake ice cream candy chocolate cake chocolate cake cotton candy dragée apple pie.
        Brownie carrot cake candy canes bonbon fruitcake topping halvah. Cake sweet roll cake cheesecake cookie chocolate cake liquorice.
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable, BAvatar, BFormInput, BRow, BCol, BFormGroup, BPagination
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    BAvatar,
    BFormInput,
    BPagination,
    BRow,
    BCol,
    BFormGroup,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      filter: null,
      sortBy: 'id',
      sortDesc: false,
      filterOn: [],
      pageOptions: [3, 5, 10],
      currentPage: 1,
      perPage: 10,
      fields: ['cover', { key: 'name', label: 'Business Name', sortable: true }, { key: 'member_name', label: 'referral source', sortable: true }, { key: 'contact_name', label: 'Recommend To', sortable: true }, { key: 'contact_email', label: 'Recommend To Email', sortable: true }, { key: 'contact_phone', label: 'Recommend To Phone', sortable: true }, { key: 'recommendation_date', label: 'Recommended At' }],
    }
  },
  computed: {
    recommendations() {
      return this.$store.state.recommend.vendor_recommendations
    },
    totalRows() {
      return this.recommendations.length
    },
    userData() {
      return this.$store.state.auth.user
    },
  },
  mounted() {
    this.getRecomendations()
  },
  methods: {
    getRecomendations() {
      if (this.userData.user_info.member.is_vendor === true) {
        this.$store.dispatch('recommend/getVendorsRecommendations', this.userData.user_info.member.id)
      } else {
        this.$store.dispatch('recommend/getRecommendations')
      }
    },
    date(date) {
      return moment(date).format('ddd DD-MMM-YY hh:mm a')
    },
    getImage(image) {
      return process.env.VUE_APP_STORAGE_PATH + image
    },
    showModal() {
      this.$bvModal.show('app-modal')
    },
    hide() {
      this.$bvModal.hide('app-modal')
    },
    showDetails() {
      this.is_update = true
      this.showModal()
    },
  },
}
</script>

<style>

</style>
